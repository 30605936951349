export default function getSeoProperty(object) {
  if (!object) return null;
  if (object.hasOwnProperty("seo")) return object;

  for (var i = 0; i < Object.keys(object).length; i++) {
    if (typeof object[Object.keys(object)[i]] == "object") {
      var o = getSeoProperty(object[Object.keys(object)[i]]);
      if (o != null) return o["seo"];
    }
  }

  return null;
}
