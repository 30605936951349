import getSeoProperty from "@/utils/getSeoProperty";
import getTemplates from "@/utils/getTemplates";
import dynamic from "next/dynamic";
import aboutTemplateQuery from "@/graphql/templates/about.graphql";
import blogTemplateQuery from "@/graphql/templates/blog.graphql";
import blogsTemplateQuery from "@/graphql/templates/blogs.graphql";
import clientChallengeTemplateQuery from "@/graphql/templates/client-challenge.graphql";
import clientChallengesTemplateQuery from "@/graphql/templates/client-challenges.graphql";
import contentTemplateQuery from "@/graphql/templates/content.graphql";
import gatedContentTemplateQuery from "@/graphql/templates/gated-content.graphql";
import indexQuery from "@/graphql/templates/home.graphql";
import productCategoryTemplateQuery from "@/graphql/templates/product-category.graphql";
import productTemplateQuery from "@/graphql/templates/product.graphql";
import productsTemplateQuery from "@/graphql/templates/products.graphql";
import requestDemoTemplateQuery from "@/graphql/templates/request-demo.graphql";
import searchTemplateQuery from "@/graphql/templates/search.graphql";
import supportTemplateQuery from "@/graphql/templates/support.graphql";
import thankYouTemplateQuery from "@/graphql/templates/thank-you.graphql";
import useCaseCategoryTemplateQuery from "@/graphql/templates/use-case-category.graphql";
import useCaseTemplateQuery from "@/graphql/templates/use-case.graphql";
import useCasesTemplateQuery from "@/graphql/templates/use-cases.graphql";
import whitepapersTemplateQuery from "@/graphql/templates/whitepapers.graphql";
import AppLayout from "@/layouts/AppLayout";
import graphqlClient from "@/lib/graphql-client";
import LayoutService from "@/services/LayoutService";
import ThemeService from "@/services/ThemeService";

const RenderContentDynamic = ({ data, template, theme, layout, id }) => {
  const DynamicTemplate = dynamic(() => import("@/templates/" + template));
  const seo = getSeoProperty(data);

  return (
    <AppLayout seo={seo} theme={theme} layout={layout}>
      <DynamicTemplate data={data} id={id} theme={theme} />
    </AppLayout>
  );
};

export async function getStaticPaths() {
  const templates = await getTemplates();
  const paths = templates.map((template) => {
    const uri = template.uri;
    const params = uri.split("/").filter((uri) => uri !== "");

    return { params: { slug: params } };
  });

  return { paths, fallback: false };
}

export async function getStaticProps(context) {
  const slug = context.params.slug || "/";
  const formattedSlug = slug[0].length > 1 ? `/${slug.join("/")}/` : "/";

  const theme = await ThemeService().settings();
  const layout = await LayoutService();
  const templates = await getTemplates();
  const template = templates.find((template) => template.uri === formattedSlug);

  if (!template) return { notFound: true };

  const { pageTemplate, id } = template;
  const response = await graphqlClient(templateQueries[pageTemplate], { id });
  const data = response?.data || null;

  // Resolve this with an error page when data can't be resolved
  if (!data) {
    return { notFound: true };
  }

  return {
    props: { theme, layout, data, id, template: pageTemplate },
  };
}

const templateQueries = {
  index: indexQuery,
  about: aboutTemplateQuery,
  blog: blogTemplateQuery,
  blogs: blogsTemplateQuery,
  whitepapers: whitepapersTemplateQuery,
  content: contentTemplateQuery,
  "gated-content": gatedContentTemplateQuery,
  "client-challenge": clientChallengeTemplateQuery,
  "client-challenges": clientChallengesTemplateQuery,
  "request-demo": requestDemoTemplateQuery,
  search: searchTemplateQuery,
  "product-category": productCategoryTemplateQuery,
  product: productTemplateQuery,
  "use-case-category": useCaseCategoryTemplateQuery,
  "use-case": useCaseTemplateQuery,
  products: productsTemplateQuery,
  "use-cases": useCasesTemplateQuery,
  support: supportTemplateQuery,
  "thank-you": thankYouTemplateQuery,
};
export default RenderContentDynamic;
